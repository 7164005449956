import * as React from "react";
import { Grid, Paper } from "@material-ui/core";
import ReactMarkdown from "react-markdown";
import { Layout } from "../common/Layout";
import { SEO } from "../components/seo";
import { Heading } from "../components/heading";

const termsMarkdown = `
Our terms

1. These terms

    1.1. **What these terms cover**. These are the terms and conditions on which we provide our membership service to you.

    1.2 **Why you should read them**. Please read these terms carefully. These terms tell you who we are, the service we provide and how sellers will provide cards to you, how you and we may change or end the contract, what to do if there is a problem and other important information. If you think that there is a mistake in these terms or require any changes,please contact us to discuss.

2. Information about “us” and how to contact us

    2.1 **Who “we” are**. We are **CCG TRADER LTD** a company registered in England and Wales. Our company registration number is 12635095 and our registered office is at 64 Scott Lidgett Road, Longport, Stoke on Trent, Staffordshire, United Kingdom ST6 4NH.

    2.2 **What we do.** We provide an on-line platform which allows members to catalogue cards they have, as **‘My Collection’** offer some of those cards as being **‘for trade’** or **‘for sale’** and flag other cards as **‘want in trade’** or **‘want to buy’**. The site itself does not operate as a store and so does not hold stock. You as a member can then search for partners looking to exchange, buy or sell as the case may be.

    2.3 **How to contact us**. You can contact us by writing to us at customerservice@ccgtrader.co.uk

    2.4 **How we may contact you**. If we have to contact you we will do so by writing to you at the email address you provided to us.

    2.5 **“Buyer”.** When we use the word “buyer”, this means the party receiving a card(s) bought from a seller for monetary value or transferred in exchange for the number of cards agreed between the buyer and the seller.

    2.6 **“Commission”.** When we use the word “commission”, this means the sum of money charged by us each time a card(s) is transferred from a seller to a buyer for the sum of money agreed by the buyer and the seller.

    2.7 **“Exchanging”.** When we use the word “exchanging”, this means the buying, selling, part-exchanging and/or transferring of a card(s) from a seller to a buyer and vice versa whether for monetary value or in exchange for the number of cards agreed by the buyer and the seller.

    2.8 **“Member(ship)”.** When we use the word member or membership, this means an individual acting for purposes which are wholly or mainly outside that individual’s trade, business, craft or profession.

    2.9 **“Order”.** When we use the word “order”, this means the agreement reached by the buyer and the seller when transferring of a card(s) whether for monetary value or in exchange for the number of cards agreed between the buyer and the seller.

    2.10 **“Seller”.** When we use the word “seller”, this means the party transferring a card or cards to a buyer whether for money for monetary value or in exchange for the number of card(s) agreed by the buyer and the seller.

    2.11 **“Value”.** When we use the word “value”, this means the value of a card(s) to be exchanged between a buyer and a seller whether for monetary for value or in exchange for the number of cards agreed by the buyer and the seller.

    2.12 **"Writing"**. When we use the words "writing" or "written" in these terms, this is limited to emails only.

3. Our contract with you

    3.1 **How your order is placed and accepted**. A seller’s acceptance of your order to purchase a card(s) will take place when the seller emails you to accept your order, at which point a contract will come into existence between you and the seller.

    3.2 **If a seller cannot accept your order**. If a seller is unable to accept your order, the seller will inform you of this in writing and will not charge you for the cards(s). This might be because the card has already been sold, because we have identified an error in the price or description of the card or colour or quality or because the seller is unable to meet a delivery deadline you have specified.

    3.3 **An exchange/order number**. We recommend a seller assign an exchange/order number to each exchange/order at the time an exchange has been agreed and tells the buyer what it is when confirming the exchange/order. This will help the seller if the buyer refers to the exchange/order number should a buyer have to contact a seller about an exchange.

4. On line content

    4.1 **Cards may vary slightly from their image**. The images of the cards on our website are for illustrative purposes only. Although sellers should have made every effort to display the colours accurately, neither we nor the seller can guarantee that a device's display of the colours accurately reflects the colour of the cards. Cards may vary slightly from those images displayed.

    4.2 **Card packaging may vary**. We are not responsible for the packaging of any card under any circumstances.

5. Our rights to make changes

    5.1 **Updates to digital content**. We require sellers to update their digital content, provided that the digital content shall always match the description of it that the seller provided before another member bought a card.

6. Membership

    6.1 **You cannot transfer your membership to someone else.**

    6.2 **Our right to suspend membership**. We will contact you in advance to tell you if whenever reasonably possible will be suspending membership stating the reason(s) for suspending your membership. If we have to suspend membership;
       1. you will not be able to order cards while you are suspended.
       2. you will have to pay for any outstanding orders.

    6.3. **Your right to end membership.** You may contact us to suspend or end your membership at any time.

    6.4 **Ending your membership**. If you are ending your membership for any reason your membership will end immediately we receive your request.

    6.5 **Tell us you want to end your membership**. To end your membership with us, please let us know by doing one of the following:
      1. **Email**. Contact customer services by email; customerservice@ccgtrader.co.uk. Please provide your name, home address, details of the exchange and, where available, your phone number and email address.
      2. **Online**. Complete the close account form in the profile section of our website.

7. Our rights to end your membership

    7.1 **When we may end your membership.** We reserve the right to your membership with immediate effect.

    7.2 **When we may end your membership**. We may end your membership at any time by writing to you if:
      1. you do not make a payment due to a seller when it is due and you still do not make payment within 14 days of the seller reminding you that payment is due;
      2. you do not, within a reasonable time of a seller asking for it, provide the seller with information that is necessary for the seller to provide a card(s), for example, the delivery address and/or the invoice address (if different);
      3. you do not, within a reasonable time, allow a seller to deliver the card(s) ordered by you or you fail to collect them within 14 days of purchase.

    7.3 **We may withdraw the service**. We may write to you to let you know that we are going to stop providing the membership service. Unless provision of the service is forced to stop for reasons beyond our control we will let you know at least 28 days in advance of our stopping the service.

8. The provision of cards

    8.1 **Delivery costs**. The costs of delivery will be as agreed between the seller and you.

    8.2 **We are not responsible for delay or failure to deliver**. We will not be liable for any failure or delay caused by any event. It will be your responsibility accept delivery of the card(s) to the address you gave to the seller.

    8.3 **When you own a card**. You own a card once the seller has received payment in full.

    8.4 **What will happen if you do not give a seller the required information.** A seller will require the delivery address and postcode from you so that the seller can supply cards to you. If you do not give the seller this information within a reasonable time of placing your order, or if you give incomplete or incorrect information, the seller may either treat the order as being withdrawn or make an additional charge of a reasonable sum to compensate for any extra work that is required as a result.

    8.5 **When a seller is not responsible for supplying cards.** A seller is not responsible for supplying cards late or at all if this is caused by you not giving the seller the requisite and complete delivery information needed within a reasonable time of an order being placed or the seller asking for it.

    8.6 **Reasons we may request sellers to suspend the supply of cards to you**. We may require sellers to suspend the supply of a card to:
      1. deal with technical problems or make minor technical changes to the website;
      2. update the website to reflect changes in relevant laws and regulatory requirements;
      3. where your membership has ended for any reason;

    8.7 **A seller may also suspend supply of cards if you do not pay**. If you do not pay a seller for the cards when you are supposed to and you still do not make payment within 14 days of a seller reminding you that payment is due, we may suspend your membership and supply of cards until you have paid the seller the outstanding amounts. We will contact you to tell you we are suspending your membership. We will not suspend membership where you are in dispute with a seller.

9. Your rights to end the contract

    9.1 **You can end your contract with a seller**. Your rights when you end a contract will depend on whether there is anything wrong with your exchange and when you decide to end the contract:
      1. **If what you have bought is faulty or mis-described you may have a legal right to end your contract with the seller** (or to get the card repaired or replaced or to get some or all of your money back)**, see** clauses 9.5;
      2. **If you have just changed your mind about a card, see** clause 9.2. You may be able to get a refund but this will be subject to you reaching agreement with the seller and there may be deductions and you will have to pay the costs of return of any goods.
      3. **In all other cases if the seller is not at fault and there is no right to change your mind.**

    9.2 **When you do not have the right to change your mind**. You do not normally have a right to change your mind in respect of any cards after the order has been acknowledged by the seller.

    9.3 **Ending a contract where the seller is not at fault - there is no right to change your mind**. If a seller is not at fault and you do not have a right to change your mind, you can still end a contract to purchase a card or cards before the contract is completed, but you may have to pay the seller compensation. A contract for goods is completed when the card is delivered and paid for. If you want to end a contract before it is completed where the seller is not at fault and you have changed your mind, contact the seller and to let the seller know you wish to cancel the contract. If the seller agrees the contract will end immediately and the seller will refund any sums paid by you for cards not provided but the seller may deduct from that refund (or, if you have not made an advance payment, charge you) compensation for the net costs the seller will incur as a result of your ending the contract depending on the date on which you end the contract, as compensation for the net costs the seller will incur as a result of your doing so.

    9.4 **Returning cards after ending your membership**. If you end membership for any reason after cards have been dispatched to you or you have received them, you must still pay for the card(s) or return them to the seller. If you are exercising your right to change your mind you must send off the goods within 7 days of telling the buyer you wish to end the contract.

    9.5 **When the seller will pay the costs of return**. The seller will only pay the costs of return:
      1. if the cards are faulty or mis-described;
      2. if the seller agrees to you changing your mind.

    In all other circumstances (including where you are exercising your right to change your mind) you must pay the costs of return.

    9.6 **Deductions from refunds if you change your mind.** The seller may reduce your refund of the price (excluding delivery costs) to reflect any reduction in the value of the cards, if this has been caused by your handling them in a way which would not be permitted in a shop. If the seller refunds you the price paid before the seller is able to inspect the goods and later discovers you have handled them in an unacceptable way, you must pay the seller an appropriate amount.

    9.7 **When your refund will be made**. The seller will make any refunds due to you as soon as possible. If you are exercising your right to change your mind then:
      1. If the seller has not offered to pay for collection, your refund will be made within 14 days from the day on which the seller receives the cards back from you or, if earlier, the day on which you provide the seller with evidence that you have sent the card back to the seller.
      2. In all other cases, your refund will be made within 14 days of your telling the seller you have changed your mind

    9.8 **No right to refund of commission.** No commission will be refunded whenever a contract has been cancelled because the buyer changed its mind about a purchase.

    9.9 **Your obligation to return rejected cards**. If you wish to exercise your legal rights to reject a card(s) you must post them back to the seller within 7 days of receipt.

10. If there is a problem with a card

     10.1 **How to tell a seller about problems**. If you have any questions or complaints about a card, please contact the seller.

     10.2 **Summary of your legal rights**. The seller is under a legal duty to supply cards that are in conformity with their description. Nothing in these terms will affect your legal rights.

11. Price and payment

     11.1 **Where to find the price for the card**. The price of the card (which includes VAT, if any is payable) will be the price indicated on the order pages when you placed your order. The seller will take all reasonable care to ensure that the price of the card advised to you is correct. However please **see** clause 11.2 for what happens if you discover an error in the price of the card you order.

     11.2 **What happens if we got the price wrong**. It is always possible that, despite our best efforts, some of the cards advertised may be incorrectly priced. If the card’s correct price at your order date is higher than the price stated to you, you should contact the seller before placing your order. If a seller accepts and processes your order where a pricing error is obvious and unmistakable and could reasonably have been recognised by you as a mispricing, the seller may end the contract, refund you any sums you have paid and require the return of any cards provided to you.

     11.3 **What to do if you think an invoice is wrong**. If you think an invoice is wrong please contact the seller promptly to let the seller know.

12. Commission and payment

     12.1 The commission payable to us by the buyer and the seller is the sum of money payable to us immediately on completion of a sale. The value of our commission will be set out in our price list in force at the time a buyer places an order. Our commission may change at any time without notice, but commission changes will not apply to orders that have been confirmed before any commission change has been made.

     12.2 Commission will include VAT, if any is payable. However, if the rate of VAT changes between the date of the Order and the date of delivery or performance, we will adjust the rate of VAT that you pay, unless you have already paid for the card(s) in full before the change in the rate of VAT takes effect.

     12.3 Commission will be deducted automatically and immediately an exchange/order has been confirmed by the system.

     12.4. Commission will not be refunded should an exchange fail to complete for whatever reason once an exchange has been confirmed.

13. Our responsibility for loss or damage suffered by you

     13.1 **We are not responsible to you for foreseeable loss and damage**. If a seller fails to comply with these terms, we are not responsible under any circumstances for loss or damage you suffer, whether or not such loss or damage is foreseeable. Loss or damage is foreseeable if either it is obvious that it will happen or if, at the time the contract with a seller was made, both the seller and you knew it might happen, for example, if you discussed it with us during the sales process.

     13.2 **We are not responsible to you for any error or omission posting by a seller or on-line posting by a member.** If a seller fails to properly or accurately upload a card and or properly declare information which might affect the value of a card, for example but not limited to, the age, condition, quality of a card. Nor shall we be responsible for any customer feedback posted to our website or any other website or media platform.

     13.3 **We do not exclude or limit in any way our liability to you where it would be unlawful to do so**. This includes liability for death or personal injury caused by our negligence or the negligence of our employees, agents or subcontractors; for fraud or fraudulent misrepresentation; for breach of your legal rights in relation to the cards including the right to receive cards which are: as described and match information we provided to you and any sample or model seen or examined by you; of satisfactory quality; fit for any particular purpose made known to the seller; supplied with reasonable skill and care; and for defective cards under the Consumer Protection Act 1987

     13.4 **We are not liable for business losses**. Membership is only private users. If you use the cards for any commercial, business or re-sale purpose we will have no liability to you for any loss of profit, loss of business, business interruption, or loss of business opportunity.

14. **What to do if a buyer makes a return request**

     14.1. If a buyer wants to return an item to you, how you can respond will depend on why the buyer wants make a return. If the buyer opens a return request because the item is damaged, doesn't match the listing description, or they received the wrong item, you'll have to accept their request, even if you don't accept returns.

     14.2 If the buyer is sending an item back because it doesn't match the listing description or it arrived damaged or faulty, you are responsible for the cost of return postage, even if you don't offer returns. If the buyer simply changed their mind or ordered the wrong item, the buyer must pay for return postage unless you offer free returns.

15. **What to do if an order is not fulfilled as a buyer**

    15.1 As a buyer you have 7 calendar days from the estimated delivery date to inform the seller that you didn't receive your cord(s). Once you've told the seller there's a problem, the seller has 7 calendar days to provide a delivery update, offer a replacement, or give you a refund.

    15.2 If delivery to your postcode takes place after you opened the item for which you made a ‘not received request’, both you and the seller will get an email notification. Your request will then close automatically within 24 hours. However you can ask us to step in and help within this time, if you still haven't received your item.

16. **Refunding the buyer**

    16.1 When you agree to give the buyer their money back, or once you receive the returned item, you'll need to issue the buyer’s refund within 2 business days. Instructions of how to do this can be found on our [help](/help) page. If you don't refund the buyer within 2 business days of receiving the item, and the buyer has uploaded return tracking, we may automatically issue a refund to them on your behalf.

    16.2 When you issue a refund to the buyer, they will be refunded through their original payment method. It usually takes 3 to 5 business days for a PayPal refund to go through. If they paid with a credit card, it may take up to 30 days to process depending on their card provider.

    16.3 When you issue a refund to the buyer before we're asked to step in and help, your final value fee will show as a credit on your next invoice.

17. **Partial refund guidelines where the item is returned used or damaged**

    17.1 In most circumstances a seller will issue a full refund to the buyer. If an item is returned used or damaged it may be appropriate to issue a partial refund. See our seller protection policy to find out how a seller qualifies to issue a partial refund.

18. **How to accept a return**

    18.1 Instructions of how to do this can be found on our [help](/help) page.

19. **How to issue a full refund**

    19.1 To offer a full refund:

    Instructions of how to do this can be found on our [help](/help) page.

    19.2 **How to offer a partial refund**

    Instructions of how to do this can be found on our [help](/help) page.

    19.3 **How to decline a return**

    You can only decline a return if the buyer is returning the item because they changed their mind, and your return policy stated you don't accept returns.

    To decline a return:

    Instructions of how to do this can be found on our [help](/help) page.

    19.4 **How to send the buyer a message**

    To send the buyer a message:

    Instructions of how to do this can be found on our [help](/help) page.

    19.5 **How to issue a partial refund when the item is returned in a different condition**

    To offer a partial refund:

    Instructions of how to do this can be found on our [help](/help) page.

    19.6 **How to specify a returns address**

    Instructions of how to do this can be found on our [help](/help) page.

    19.7 **Refunding the buyer**

    When you agree to give the buyer their money back, or once you receive the returned item, you'll need to issue their refund within 2 business days. Instructions of how to do this can be found on our [help](/help) page. If you don't refund the buyer within 2 business days of receiving the item, and the buyer has uploaded return tracking, we may automatically issue a refund to them on your behalf.

    When you issue a refund to the buyer, they'll be refunded through their original payment method. It usually takes 3 to 5 business days for a PayPal refund to go through. If they paid with a credit card, it may take up to 30 days to process depending on their card provider.

    We'll also automatically relist your item for you. If you don’t want your item to be relisted, simply untick the Relist item box when issuing the refund.

20. **Events Outside Our Control**

    20.1 We will not be liable or responsible for any failure to perform, or delay in performance of, any of our obligations or thee obligations of a member under these Terms that is caused by an Event Outside Our Control.

    20.2 An Event Outside Our Control means any act or event beyond our reasonable control, including without limitation strikes, lock-outs or other industrial action by third parties, civil commotion, riot, invasion, terrorist attack or threat of terrorist attack, war (whether declared or not) or threat or preparation for war, fire, explosion, storm, flood, earthquake, subsidence, epidemic or other natural disaster, or failure of public or private telecommunications networks.

21. **Dispute resolution**

    21.1 We always encourage our members to communicate with each other when there's a problem with a transaction. The first step is to contact the member through the Members’ Resolution Centre and try to resolve the problem.

    21.2 To start the resolution process, please tell us what your problem is:
      [LINK](/help) I bought an item.
      [LINK](/help) I haven't received it yet.
      [LINK](/help) I bought an item.
      [LINK](/help) I received an item that does not match the seller's description.
      [LINK](/help) I swapped an item.
      [LINK](/help) I haven't received my payment yet.
      [LINK](/help) The buyer and I agree to cancel a transaction.
      [LINK](/help) My problem is not listed above.

22. **How we may use your personal information**

    22.1 We will only use your personal information as set out in our [PRIVACY POLICY](/privacy).

23. **Other important terms**

    23.1 **We may transfer this agreement to someone else**. We may transfer our rights and obligations under these terms to another organisation.

    23.2 **Nobody else has any rights under this contract**

    23.3 **If a court finds part of this contract illegal, the rest will continue in force**. Each of the paragraphs of these terms operates separately. If any court or relevant authority decides that any of them are unlawful, the remaining paragraphs will remain in full force and effect.

    23.4 **Even if we delay in enforcing this contract, we can still enforce it later**. If we do not insist immediately that you do anything you are required to do under these terms, or if we delay in taking steps against you in respect of your breaking this contract, that will not mean that you do not have to do those things and it will not prevent us taking steps against you at a later date. For example, if you miss a payment and we do not chase you but we continue to provide membership services, you are still require you to make the payment at a later date.

    23.5 **Which laws apply to this contract and where you may bring legal proceedings**. These terms are governed by English law and you can bring legal proceedings in respect of the cards in the English courts. If you live in Scotland you can bring legal proceedings in respect of the cards in either the Scottish or the English courts. If you live in Northern Ireland you can bring legal proceedings in respect of the cards in either the Northern Irish or the English courts.
`;

function Terms() {
    return (
        <Layout>
            <SEO title="Terms &amp; Conditions" />

            <section>
                <Grid container spacing={4}>
                    <Grid item xs={12}>
                        <Heading text="Terms &amp; Conditions" />
                    </Grid>
                    <Grid item xs={12}>
                        <Paper className="markdown p-4">
                            <ReactMarkdown>{termsMarkdown}</ReactMarkdown>
                        </Paper>
                    </Grid>
                </Grid>
            </section>
        </Layout>
    );
}

export default Terms;
